<template>
  <div></div>
</template>

<script>

export default {
  name: 'ChangeLocale',

  data() {
    return {
      previousRoutePath: undefined,
    };
  },

  watch: {
    previousRoutePath(newVal) {
      this.$router.replace({
        path: newVal,
      });
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRoutePath = from.path;
    });
  },

  mounted() {
    const matched = this.$route.matched.slice().reverse().find((r) => r.meta && r.meta.toLocale);
    const locale = matched ? matched.meta.toLocale : 'ko';

    this.$cookies.set('language', locale);
    this.$i18n.locale = locale;

    // html lang : https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/lang
    document.documentElement.lang = locale;
  },
};
</script>
